<style lang="scss">
.picker-selected{
  font-weight: bold;
}

.mint-search-list{
  position: inherit;
  padding-top: 2px;
}
.line-label{
  font-size: 0.14rem;
}

.exp-line-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-top: 0.10rem ;
}

.exp-line-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;

  color: #3b5998;
}




.finish-panel {
  .panel-title {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 15px;
  }

  .panel-content {
    padding: 15px;
  }

}
.wrap{
  display:flex;
  flex-direction:column;
  height:100%;
}
.footer0{
  height:40px;
  width: 50%;
  position: fixed;
  bottom:0;
  left:0;
  z-index: 10;
}
.footer1{
  height:40px;
  width: 50%;
  position: fixed;
  bottom:0;
  left:50%;
  z-index: 10;
}
.view1{
  color: black;
  margin-right: 8px;
  font-size: 0.14rem;
}
.mint-cell{
  display: block;
}
.mint-radiolist-title{
  display: inline-block;
  color: black;
  font-size: 14px;

}
.app-header {
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  //border-bottom: solid 1px rgba(0, 0, 0, 0.03);
  //box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.08);
  z-index: 2;

  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.header-left, .header-right {
  height: 100%;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

}

.header-left {
  left: 0;
}

.header-right {
  right: 0;
}
.icon-left {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.go-home {
  display: block;
  width: 21px;
  height: 19px;
  background: url("../assets/images/go-home.png");
  background-size: contain;
}
.payImg1 {

  right: 0;
  top: 50px;
  display: inline-block;

  .grid-cell-1-3{
    width: 100px;
    height: 100px;
  }
}

</style>
<template>
  <div>
    <div class="app-header" >
      事件响应编辑
      <div class="header-left" @click="$router.push({path:'/event-feedback'})"><i class="iconfont icon-prev"></i></div>
      <div class="header-right" @click="goHome()"><i class="go-home"></i></div>
    </div>
    <Panel class="tabs">
      <template slot="title">
        <div :class="`tab${tabIndex===0?' active':''}`" @click="switchLicenses(0)">基本信息</div>
        <div :class="`tab${tabIndex===1?' active':''}`" @click="switchLicenses(1)">响应信息</div>
      </template>
      <div slot="content"  >
        <div class="flex-dir-row mt15" v-if="tabIndex===0">


          <label class="line-label view1"><span style="color: red">*</span>事件类型</label>
          <div class="flex-item-auto">
            <input class="line-input"  @click="showeventType" v-model="eventTypeName"
                   @keydown="preventDefault" placeholder="事件类型"/>
          </div>

        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1"><span style="color: red">*</span>反馈人</label>
          <div class="flex-item-auto">
<!--            <input class="line-input" @click="showType" v-model="feedbackUserName"-->
<!--                   @keydown="preventDefault"/>-->
            <input class="line-input" @click="clickfeedbackUserName" placeholder="反馈人" v-model="feedbackUserName"
                   />

          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1"><span style="color: red">*</span>反馈时间</label>
          <div class="flex-item-auto">
            <input class="line-input" @click="openPicker" placeholder="反馈时间" v-model="feedbackDate"
            />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1">设备名称</label>
          <div class="flex-item-auto">
            <input class="line-input" id="eqName" @click="showeqName" v-model="eqName"
                   @keydown="preventDefault" placeholder="设备名称"/>
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1" >描述</label>
          <div class="flex-item-auto">
            <textarea class="line-input" rows="4" style="height: 70px" placeholder="描述" id="" maxlength="1000" v-model="remark"
            />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0" style="position:relative;">
          <label class="line-label view1" style="position: absolute;top: 8px">相关文件</label>
          <FileUpload2 space="jsbFile" @showfile1list="gettabIndex" style="position: absolute;right: 0.20rem" :tabindex="0" max-size="10" ref="fileUpload2" :editable="true" class="payImg1"/>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1">
          <label class="line-label view1" >处理响应<br>时间</label>
          <div class="flex-item-auto">
            <input class="line-input"  @click="openPickerNext" placeholder="处理响应时间" v-model="handledDate"
            />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1">
          <label class="line-label view1" >受理响应时长(分钟)</label>
          <div class="flex-item-auto">
            <input class="line-input" type="number" min="0" maxlength="10"    v-model="acceptTime"
            />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1">
          <label class="line-label view1" >响应结果</label>
          <div class="flex-item-auto">
            <textarea class="line-input" rows="4" style="height: 70px" placeholder="响应结果" maxlength="1000" v-model="responseResult"
            />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1" style="position:relative;">
          <label class="line-label view1" style="position: absolute;top: 8px">相关文件</label>
          <FileUpload2 space="jsbFile" max-size="10" style="position: absolute;right: 0.20rem" @showfile2list="gettabIndex" :tabindex="1" ref="fileUpload2" :editable="true" class="payImg1"/>
        </div>
      </div>

    </Panel>
    <div class="btn footer0" style="background-color: white;color: black!important; border: 1px #E6E6E6 solid" @click="cancel">取消</div>
    <div class="btn  green footer1" @click="save">保存</div>

    <mt-popup
        v-model="addfeedbackUserName"
        position="top"
        v-if="addfeedbackUserName"
    >

      <select-search v-model="selectfeedbackUserName"
                 placeholder="反馈人"
                 @visible="getvisible"
                 @closevisible="closevisible"

      >
        <selectcell
            v-for="item in filterResult"
            :title="item.text"
            @click.native="selectserach(item)"
        >
        </selectcell>
      </select-search>


    </mt-popup>
    <mt-popup
        v-model="showTypeVisible"
        position="bottom"
    >
      <mt-picker :slots="typeSlots" @change="ontypeChange" value-key="text" v-if="showTypeVisible"></mt-picker>
    </mt-popup>
    <mt-popup
        v-model="showeqNames"
        position="bottom"
    >
      <mt-picker :slots="eqNames" @change="ontypeEqChange" value-key="text" v-if="showeqNames"></mt-picker>
    </mt-popup>
    <mt-popup
        v-model="showeventTypes"
        position="bottom"
    >
      <mt-picker :slots="eventTypeslots" @change="oneventTypeChange" value-key="text" v-if="showeventTypes"></mt-picker>
    </mt-popup>
    <mt-popup
        v-model="showMaintainer"
        position="bottom"
    >
      <mt-picker :slots="maintainertypeSlots" @change="onMaintainersChange" value-key="text" v-if="showMaintainer"></mt-picker>
    </mt-popup>
    <mt-datetime-picker
        ref="picker"
        type="datetime"
        @confirm="format"
        v-model="feedbackDate1">
    </mt-datetime-picker>
    <mt-datetime-picker
        ref="pickerNext"
        type="datetime"
        @confirm="formatNextDate"
        v-model="handledDate1">
    </mt-datetime-picker>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import {InfiniteScroll , Radio, DatetimePicker} from 'mint-ui';
import {Toast, MessageBox} from 'mint-ui';
import dict from "@/plugins/dict";
import store from "@/plugins/store";
import Panel from "@/components/Panel";
import {mapState} from "vuex";
import FileUpload2 from "@/components/FileUpload2";
import selectSearch from "@/components/selectSearch"
import selectcell from "@/components/selectcell"
export default {
  components: {
    Panel,
    AppHeader,
    selectSearch,
    selectcell,
    InfiniteScroll,
    Radio,
    DatetimePicker,
    dict,
    FileUpload2,

  },
  data() {
    return {
      addfeedbackUserName: false,
      page: 1,
      tabIndex: 0,
      mainTain: null,
      eventTypeName: null,
      eventTypeCode: null,
      feedbackUserId: null,
      feedbackUserName: null,
      eqName: null,
      eqId: null,
      feedbackDate: null,
      feedbackDate1: new Date(),
      remark: null,
      maintainerName: null,
      maintainerCode: null,
      acceptTime: 0,
      handledDate: null,
      handledDate1:  new Date(),
      responseResult: null,
      maintainTypes: [],
      loading: false,
      noMore: false,
      showTypeVisible: false,
      showeqNames: false,
      showeventTypes: false,
      showMaintainer: false,
      selectfeedbackUserName: null,
      id: null,
      //数据字典
      users: [],
      maintainers: [],
      equipments: [],
      eventTypes: [],
      //文件上传
      file1Ary: [],
      file2Ary: [],
      //人员是否存在
      exist: true
    }
  },

  computed:
      {
      ...mapState({
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    eventTypeslots(state) {
      this.eventTypes= state.eventTypes
      return [{values: state.eventTypes}]

    },
    typeSlots(state) {

      this.users=state.users;
      return [{values: state.users}]
    },
    eqNames(state) {
      this.equipments=state.equipments;
      return [{values: state.equipments}]
    },
    maintainertypeSlots(state) {
      this.maintainers=state.maintainers
      return [{values: state.maintainers}]
    },

  }),
  filterResult() {
          // for ()
          var a= this.users.filter(value => new RegExp(this.selectfeedbackUserName).test(value.text))
          return a;
        }
},

  methods: {
    initAddEventFeedback(){
      this.id=this.$route.query.eventFeedbackId;
      this.page = 1
      this.noMore = false
      if(this.id!=null){
        this.loadEventFeedback()
      }

    },
    closevisible(){
      this.addfeedbackUserName=false
    },
    getvisible(d){
      this.addfeedbackUserName=false
        var isexist=true
      if(this.selectfeedbackUserName!=null&&this.selectfeedbackUserName!=''){
        for (var i=0;i<this.users.length;i++){
          if(d==this.users[i].text){
            this.feedbackUserName=this.users[i].text
            this.feedbackUserId=this.users[i].id
            this.exist=true
            isexist=false
            break;
          }
        }
        console.log("isexist",isexist)
        if(isexist){
          this.feedbackUserId=null
          this.feedbackUserName=this.selectfeedbackUserName
        }
      }
    },
    clickfeedbackUserName(){
      this.addfeedbackUserName=true
      this.selectfeedbackUserName=this.feedbackUserName
      // this.$refs.selectSearch.focus();
    },
    selectserach(item) {
      this.selectfeedbackUserName = item.text
      // this.feedbackUserId=item.id
    },

    loadEventFeedback(){
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('id',`${this.id}`)
      this.$http.post(`/api/eventFeedback/edit`, data).then(res=>{
        var data=res.data
        if (data){
          if(data.eventType!=null){
            that.queryeventTypeById(data.eventType)
          }
          this.exist=data.exist
          if(data.feedbackUser!=null){
            if(data.exist){
              that.queryuserById(data.feedbackUser)
            }else {
              that.feedbackUserName=data.feedbackUserName
            }

          }
          if(data.feedbackDate!=null){
            var a= this.$moment(data.feedbackDate).format('YYYY-MM-DD HH:mm:ss')
            that.feedbackDate=a
          }
          if(data.equipmentId!=null){
            that.queryEqNameById(data.equipmentId)
          }
          if(data.remark!=null){
            that.remark=data.remark
          }
            //文件上传

          if(data.fileId1!='null'&&data.fileId1!='[]'){
            var fileId1s=(data.fileId1.slice(2,-2)).replace(/","/g,",")
            var fileId1sAry=fileId1s.split(",")
            that.file1Ary=fileId1sAry
            that.$refs.fileUpload2.initFilesByUuids(fileId1sAry,this.tabIndex)
          }

          if(data.fileId2!='null'&&data.fileId2!='[]'){
            var fileId2s=(data.fileId2.slice(2,-2)).replace(/","/g,",")
            var fileId2sAry=fileId2s.split(",")
            that.file2Ary=fileId2sAry
            // that.$refs.fileUpload2.initFilesByUuids(fileId2sAry)
          }

          if(data.handledDate!=null){
            var a= this.$moment(data.handledDate).format('YYYY-MM-DD HH:mm:ss')
            that.handledDate=a
          }
          if(data.acceptTime!=null){
            that.acceptTime=data.acceptTime
          }

          if(data.responseResult!=null){
            that.responseResult=data.responseResult
          }


        }else {
          this.noMore = true
        }
      })
    },
    queryuserById(id){
      this.typeSlots;
      var eqAry= this.users
      if(eqAry.length>0){
        for (var i=0;i<eqAry.length;i++){
          if(id==eqAry[i].id){
            this.feedbackUserName=eqAry[i].text
            this.feedbackUserId=eqAry[i].id
          }
        }
      }
    },
    queryeventTypeById(id){
      this.eventTypeslots;
      var eqAry= this.eventTypes
      if(eqAry.length>0){
        for (var i=0;i<eqAry.length;i++){
          if(id==eqAry[i].id){
            this.eventTypeName=eqAry[i].text
            this.eventTypeCode=eqAry[i].id
          }
        }
      }
    },
    queryEqNameById(id){
      this.eqNames;
      var eqAry= this.equipments
      if(eqAry.length>0){
        for (var i=0;i<eqAry.length;i++){
          if(id==eqAry[i].id){
            this.eqName=eqAry[i].text
            this.eqId=eqAry[i].id
          }
        }
      }
    },
    queryMaintainerBycode(code){
      this.maintainertypeSlots
      var mtAry=this.maintainers
      if(mtAry.length>0){
        for (var i=0;i<mtAry.length;i++){
          if(code==mtAry[i].id){
            this.maintainerName=mtAry[i].text
            this.maintainerCode=mtAry[i].id
          }
        }
      }

    },
    gettabIndex(data){
      var files=data.files
      if(data.tabIndex==0){
        this.file1Ary=[]
        for (var i=0;i<files.length;i++){
          if(files[i].fid!=""&&files[i].fid!=null){
            this.file1Ary.push(files[i].fid)
          }

        }
      }
      if(data.tabIndex==1){
        this.file2Ary=[]
        for (var i=0;i<files.length;i++){
          if(files[i].fid!=""&&files[i].fid!=null){
            this.file2Ary.push(files[i].fid)
          }

        }
      }
    },

    ontypeChange(picker, values) {
      this.feedbackUserName = values[0].text
      this.feedbackUserId = values[0].id
    },
    ontypeEqChange(picker, values) {
      this.eqName = values[0].text
      this.eqId = values[0].id
    },
    onMaintainersChange(picker, values) {
      this.maintainerName = values[0].text
      this.maintainerCode = values[0].id
    },
    oneventTypeChange(picker, values) {
      this.eventTypeName = values[0].text
      this.eventTypeCode = values[0].id
    },

    switchLicenses(tabIndex){
      var that=this;
      this.tabIndex=tabIndex;
      setTimeout(function (){
        if(tabIndex==0){
          that.$refs.fileUpload2.initFilesByUuids(that.file1Ary,tabIndex)
        }
        if(tabIndex==1){
          that.$refs.fileUpload2.initFilesByUuids(that.file2Ary,tabIndex)
        }
      },150)

    },

    showType(){
      this.showTypeVisible = true
    },
    showeventType(){
      this.showeventTypes = true
    },
    showeqName(){
      this.showeqNames = true
    },
    showTypemaintainer(){
      this.showMaintainer=true
    },
    preventDefault(e) {
      e.preventDefault()
    },
    preventMaintainer(e) {
      e.preventMaintainer()
    },
    openPicker() {
      this.$refs.picker.open();
    },
    openPickerNext() {
      this.$refs.pickerNext.open();
    },
    format(val){
      this.feedbackDate=this.$moment(val).format('YYYY-MM-DD HH:mm:ss')
      if(this.feedbackDate!=null&&this.handledDate!=null){
        var feedbackTime = Date.parse(new Date(this.feedbackDate));
        var handledTime = Date.parse(new Date(this.handledDate));
        if(feedbackTime<handledTime){
          this.acceptTime=Math.floor((handledTime-feedbackTime)/1000/60)
        }else {
          this.acceptTime=0
        }
      }else {
        this.acceptTime=0
      }

    },
    formatNextDate(val){
      this.handledDate=this.$moment(val).format('YYYY-MM-DD HH:mm:ss')

      if(this.feedbackDate!=null&&this.handledDate!=null){
        var feedbackTime = Date.parse(new Date(this.feedbackDate));
        var handledTime = Date.parse(new Date(this.handledDate));

        if(feedbackTime<handledTime){
          this.acceptTime=Math.floor((handledTime-feedbackTime)/1000/60)
        }else {
          this.acceptTime=0
        }
      }else {
        this.acceptTime=0
      }

    },
    goHome() {
      this.$router.push("/")
    },
    save(){
      if(this.feedbackUserId==null&&this.feedbackUserName!=null){
        this.feedbackUserId=this.feedbackUserName
        this.exist=false
      }
      if(this.eventTypeCode!=null&&this.feedbackUserId!=null&&this.feedbackDate!=null){
        var file1= JSON.stringify(this.file1Ary)
        var file2= JSON.stringify(this.file2Ary)
        const that = this
        this.loading = true
        let data = new FormData()
        data.append('fileId1',file1)
        data.append('fileId2',file2)
        this.id&&data.append('id',`${this.id}`)
        this.eventTypeCode&&data.append('eventType',`${this.eventTypeCode}`)
        data.append('exist',`${this.exist}`)
        this.feedbackUserId&&data.append('feedbackUser',`${this.feedbackUserId}`)
        this.feedbackDate&&data.append('feedbackDate',`${this.feedbackDate}`)
        this.eqId&&data.append('equipmentId',`${this.eqId}`)
        this.remark&&data.append('remark',`${this.remark}`)
        this.handledDate&&data.append('handledDate',`${this.handledDate}`)

        data.append('acceptTime',`${this.acceptTime}`)


        this.responseResult&&data.append('responseResult',`${this.responseResult}`)


        this.$http.post(`/api/eventFeedback/save`, data).then(resp => {
          if (resp.success) {
            Toast("提交成功！")
            setTimeout(function (){
              that.$router.push({path: '/event-feedback'})
            },200)
          }
        })
      }else {
        Toast("必填项不能为空")
      }


    },
    cancel(){
      this.$router.push({path: '/event-feedback'})
    }


  },
  mounted() {
    this.initAddEventFeedback()
    this.tabIndex = parseInt(this.$route.query.tabIndex || '0')
    this.switchLicenses(this.tabIndex)
    this.typeSlots
  }


}
</script>
